Date.prototype.format = function (format) {
    var o = {
        "M+": this.getMonth() + 1,
        "d+": this.getDate(),
        "h+": this.getHours(),
        "m+": this.getMinutes(),
        "s+": this.getSeconds(),
        "q+": Math.floor((this.getMonth() + 3) / 3),
        "S": this.getMilliseconds()
    }
    if (/(y+)/.test(format)) {
        format = format.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(format)) {
            format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
        }
    }
    return format;
}

/**
 * 格式化时间
 * @param {Object} datetime
 */
export function toIsoDateTime(datetime) {
    return datetime.substr(0, 16).replace('T', ' ').replace(/-/g, '-');
}

/**
 * 格式化时间
 * @param {Object} datetime
 */
export function toIsoDateTimeYMD(datetime) {
    return datetime.substr(0, 10).replace('T', ' ').replace(/-/g, '-');
}

/**  
 * 转换long值为日期字符串     
 * @param l long值     
 * @param pattern 格式字符串,例如：yyyy-MM-dd hh:mm:ss     
 * @return 符合要求的日期字符串  
 */
export function getFormatDateByLong(l, pattern) {
    return getFormatDate(new Date(l), pattern);
}

/** 转换日期对象为日期字符串  
 * @param l long值  
 * @param pattern 格式字符串,例如：yyyy-MM-dd hh:mm:ss  
 * @return 符合要求的日期字符串  
 */
export function getFormatDate(date, pattern) {
    if (date == undefined) {
        date = new Date();
    }
    if (pattern == undefined) {
        pattern = "yyyy-MM-dd hh:mm:ss";
    }
    return date.format(pattern);
}

//该函数格式化金额
export function numberFormat(str, step, splitor) {
    str = str.toString();
    var fuhao = "";
    if (str.indexOf('-') > -1) {
        fuhao = "-";
        str = str.substr(1, str.length);
    }
    var xiaoshu = "";
    if (str.indexOf('.') > 0) {
        xiaoshu = str.substr(str.indexOf('.'), str.length);
        str = str.substr(0, str.indexOf('.'));
    }
    var len = str.length;
    if (len > step) {
        var l1 = len % step,
            l2 = parseInt(len / step),
            arr = [],
            first = str.substr(0, l1);
        if (first != '') {
            arr.push(first);
        };
        for (var i = 0; i < l2; i++) {
            arr.push(str.substr(l1 + i * step, step));
        };
        str = arr.join(splitor);
    };
    return fuhao + str + xiaoshu;
}

//格式化银行卡
export function bankNumFormat(str, step, splitor) {
    str = str.toString();
    var len = str.length;
    if (len > step) {
        var l1 = len % step,
            l2 = parseInt(len / step),
            arr = [],
            first = str.substr(0, l1);
        if (first != '') {
            arr.push(first);
        };
        for (var i = 0; i < l2; i++) {
            arr.push(str.substr(l1 + i * step, step));
        };
        str = arr.join(splitor);
    };
    return str;
}

//乘法函数，用来得到精确的乘法结果
//说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
//调用：accMul(arg1,arg2)
//返回值：arg1乘以arg2的精确结果
export function accMul(arg1, arg2) {
    var m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length
    } catch (e) { }
    try {
        m += s2.split(".")[1].length
    } catch (e) { }
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
}
/**
 ** 加法函数，用来得到精确的加法结果
 ** 说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
 ** 调用：accAdd(arg1,arg2)
 ** 返回值：arg1加上arg2的精确结果
 **/
export function accAdd(arg1, arg2) {
    var r1, r2, m, c;
    try {
        r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
        r2 = 0;
    }
    c = Math.abs(r1 - r2);
    m = Math.pow(10, Math.max(r1, r2));
    if (c > 0) {
        var cm = Math.pow(10, c);
        if (r1 > r2) {
            arg1 = Number(arg1.toString().replace(".", ""));
            arg2 = Number(arg2.toString().replace(".", "")) * cm;
        } else {
            arg1 = Number(arg1.toString().replace(".", "")) * cm;
            arg2 = Number(arg2.toString().replace(".", ""));
        }
    } else {
        arg1 = Number(arg1.toString().replace(".", ""));
        arg2 = Number(arg2.toString().replace(".", ""));
    }
    return (arg1 + arg2) / m;
}

/**
 ** 减法函数，用来得到精确的减法结果
 ** 说明：javascript的减法结果会有误差，在两个浮点数相减的时候会比较明显。这个函数返回较为精确的减法结果。
 ** 调用：accSub(arg1,arg2)
 ** 返回值：arg1加上arg2的精确结果
 **/
export function accSub(arg1, arg2) {
    var r1, r2, m, n;
    try {
        r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
    n = (r1 >= r2) ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

//长度处理
export function cutStr(str, num) {
    var rstr = str;
    if (str.length > num) {
        rstr = str.substring(0, num) + "...";
    }
    return rstr;
}

//去除金额中,
export function fmtToNumber(num) {
    num = num.replace(/,/g, "");
    return num;
}


//获取当前日期：如2022-08-17
export function nowtime() {
    var mydate = new Date();
    var str = "" + mydate.getFullYear();
    var mm = mydate.getMonth() + 1
    if (mydate.getMonth() > 9) {
        str += mm;
    } else {
        str += "0" + mm;
    }
    if (mydate.getDate() > 9) {
        str += mydate.getDate();
    } else {
        str += "0" + mydate.getDate();
    }
    return str;
}

export function createObjectURL(object) {
    return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object);
}