import * as core from './ajaxstart';
import {config}  from './config';
import * as navigationService from './navigation-service';

import 'malihu-custom-scrollbar-plugin';
import 'jquery-i18n-properties';
import './login';
import '../i18n/jquery.i18n';
import '../../layer/layer';
// 

var api = "/api/auth";
var state = false;
// 账户格式验证
$("#username").blur(function() {
	if ($(this).val() == '') {
		$("#errorMsg").html($.i18n.prop("login.error4"));
		$(this).focus();
		$(this).addClass("error");
	} else {
		if (/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test($(this).val()) == false) {
			$("#errorMsg").html($.i18n.prop("login.error1"));
			$(this).focus();
			$(this).addClass("error");
		} else {
			$("#errorMsg").html('');
			$(this).removeClass("error");
			state = true;
		}
	}
})
//密码验证
$("#password").blur(function() {
	if ($(this).val() == '') {
		$("#errorMsg").html($.i18n.prop("login.error2"));
		$(this).focus();
		$(this).addClass("error");
	} else {
		$("#errorMsg").html('');
		$(this).removeClass("error");
		state = true;
	}
})
//回车提交
$("body").keyup(function(event) {
	if (event.keyCode == 13) { //keyCode=13是回车键
		$("#loginBtn").click();
	}
});

//提交验证
$("#loginBtn").click(function() {
	var uname = $("#username").val();
	var pwd = $("#password").val();
	if (uname == '') {
		$("#errorMsg").html($.i18n.prop("login.error4"));
		$("#username").focus();
		$("#username").addClass("error");
	} else if (pwd == '') {
		$("#errorMsg").html($.i18n.prop("login.error2"));
		$("#password").focus();
		$("#password").addClass("error");
	} else {
		var datajson = {
			username: uname,
			password: pwd
		};
		//销毁token
		delete localStorage.wavpayToken;
		delete localStorage.expiryDate;
		//验证用户公司
		var checkurl = config.rooturl + api + "/loginCheck";
		$.ajax({
			type: "POST",
			url: checkurl,
			data: JSON.stringify(datajson),
			dataType: 'json',
			contentType: "application/json",
			error: function(data) {
				let msg = data.responseText;
				$("#errorMsg").html(msg);
			},
			success: function(result) {
				let num = result.num;
				if (num > 1) {
					var htmls = '<div class="leaderboard-content"><div class="leaderboard"><h1>Select a company</h1><ol>';
					for (let cov of result.clist) {
						htmls += '<li data-id="' + cov.cid + '"><mark>' + cov.companyname + '</mark></li>';
					}
					htmls += '</ol></div></div>';
					layer.open({
						type: 1,
						shade: false,
						title: false,
						area: ['400px', '470px'],
						content: htmls
					});
				} else {
					let cid = result.cid;
					doLogin(uname, pwd, cid);
				}
			}
		});
	}
	//公司选择事件
	$(document).on("click", ".leaderboard li", function() {
		let cid = $(this).data("id");
		var uname = $("#username").val();
		var pwd = $("#password").val();
		doLogin(uname, pwd, cid);
	});

	function doLogin(uname, pwd, cid) {
		var loginjson = {
			username: uname,
			password: pwd,
			companyid: cid
		};
		//提交数据
		var suburl = config.rooturl + api + "/login";
		$.ajax({
			type: "POST",
			url: suburl,
			data: JSON.stringify(loginjson),
			dataType: 'json',
			contentType: "application/json",
			error: function(data) {
				let code = data.status;
				if (code == 400) {
					let msg = data.responseText;
					$("#errorMsg").html(msg);
				} else if (code == 401) {
					$("#errorMsg").html($.i18n.prop("login.error2"));
				} else {
					$("#errorMsg").html($.i18n.prop("server.error"));
				}
			},
			success: function(result) {
				//置空授权返回码
				config.authorCode = 0;

				let token = result.token;
				let isFirst = result.isFirst;
				localStorage.wavpayToken = token;
				var expiryDate = new Date().getTime() + config.effective_time;
				localStorage.expiryDate = expiryDate;
				core.initajax();
				if (isFirst) {
					navigationService.goToPage("ChangeYourPassword.html");
				} else {
					navigationService.goToPage("index.html");
				}
			}
		});
	}

})
