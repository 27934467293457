class Configuration {
    rooturl = "https://cash-disbmt-api-dev.wavpay.net";//服务访问地址
    effective_time = 86390000;//有效时间
    authorCode = 0;
    undealArr = ["/api/auth", "SuccessChangePassword.html", "onboarding", "Login.html", "ChangeYourPasswordEmail.html", "ForgotYourPassword.html",
        "RequestPassword.html", "saveCompany", "SuccessRegist.html" ,"Employee_template.csv"
    ];

    get rooturl() {
        return this.rooturl;
    }

    get effective_time() {
        return this.effective_time;
    }

    get undealArr() {
        return this.undealArr;
    }

    set authorCode(val) {
        this.authorCode = val;
    }

    get authorCode() {
        return this.authorCode;
    }
}

export var config = new Configuration();