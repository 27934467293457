import jquery from 'jquery';
window.$ = window.jQuery = jquery;
import * as navigationService from './navigation-service';
import { config } from './config';
import * as utils from './utils';

export { config, utils };
	
export function CloseNotificationWrapper() {
	const notificationWrapper = $("#notification-wrapper");
	if (notificationWrapper.hasClass("expanded")) {
		notificationWrapper.removeClass("notification-wrapper-expanded");
		notificationWrapper.removeClass("expanded");
	}
}
export function CloseUserAccountWrapper() {
	const notificationWrapper = $("#user-account-wrapper");
	if (notificationWrapper.hasClass("expanded")) {
		notificationWrapper.removeClass("user-account-wrapper-expanded");
		notificationWrapper.removeClass("expanded");
	}
}
/**
 * 公共模块涉及的函数
 */
//消息
$(document).on("click", "#mark-toggle", () => {
	const markerWrapper = $("#mark-all");
	if (markerWrapper.hasClass("expanded")) {
		markerWrapper.removeClass("expanded");
		markerWrapper.fadeOut();
	} else {

		markerWrapper.addClass("expanded");
		markerWrapper.fadeIn();

		//设置消息全部已读
		$(document).on("click", ".expanded", () => {
			var suburl =config.rooturl + "/api/message/readAll";
			$.ajax({
				type: "GET",
				url: suburl,
				dataType: 'json',
				contentType: "application/json",
				error: function(data) {
					let msg = data.responseText;
					alert(msg);
				},
				success: function(result) {
					var code = result.code;
					if (code == 200) {
						getNotification(10, -1);
					}
				}
			});
		});
	}
});

$('html').on("click", function(event) {
	if ($(event.target).closest('#notification-toggle, #user-account-toggle ,#notification-wrapper,#user-account-wrapper').length === 0) {
		CloseNotificationWrapper();
		CloseUserAccountWrapper();
	}
});

/**
 * 登出
 */
$(document).on("click", "#logoutBtn", () => {
	//销毁token
	localStorage.clear();
	sessionStorage.clear();
	//跳转登录
	navigationService.goToPage("Login.html");
});
/**
 * 跳转消息中心
 */
$(document).on("click", "#seaAllNoti", () => {
	navigationService.goToPage("notification.html");
});

//金额获取焦点清空
$(document).on('focus ', '.rmAmount', function () {
	$(this).val("");
});